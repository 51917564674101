
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import Chart from "@/components/1crm/dashboard/superadmin/Widget11.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import store from "@/store";

export default defineComponent({
  name: "monitoring",

  data() {
    return {
      childData: false,
      colorLeads: "#3268FF",
      colorProces: "#52D5FF",
      colorCancel: "#E53A52",
      colorClosing: "#D097F6",
      colorLive: "#1BC4BD",
      colorTotal: "#FFB700",
      dataLeads: [
        {
          name: "",
          data: [],
        },
      ],
      dataProces: [
        {
          name: "",
          data: [],
        },
      ],
      dataCancel: [
        {
          name: "",
          data: [],
        },
      ],
      dataClosing: [
        {
          name: "",
          data: [],
        },
      ],
      dataLive: [
        {
          name: "",
          data: [],
        },
      ],
      dataTotal: [
        {
          name: "",
          data: [],
        },
      ],
      totalLeads: 0,
      totalProces: 0,
      totalCancel: 0,
      totalClosing: 0,
      totalLive: 0,
      totalTotal: 0,
    };
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    Chart,

    // AddSchool,
    // GoogleMap
  },
  mounted() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("Monitoring", ["Sekolah"]);

    this.getDataMonitor();
    // this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    // this.getData(this.paging.size, this.paging.page, this.filterConfir);
    // this.getListSekolah();
    // setCurrentPageBreadcrumbs("Data NPWP", ["Data"]);
  },
  computed: {
    to() {},
    from() {},
  },
  methods: {
    getDataMonitor() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/monitoring/2022"
      )
        .then((resp) => {
          this.totalLeads = resp.data.sumLeads;
          this.totalProces = resp.data.sumProcess;
          this.totalCancel = resp.data.sumCancel;
          this.totalClosing = resp.data.sumClosing;
          this.totalLive = resp.data.sumLive;
          this.totalTotal = resp.data.sumTotal;
          (this.dataLeads = [
            {
              name: "Leads",
              data: resp.data.leads,
            },
          ]),
            (this.dataCancel = [
              {
                name: "Cancel",
                data: resp.data.cancel,
              },
            ]),
            (this.dataClosing = [
              {
                name: "Closing",
                data: resp.data.closing,
              },
            ]),
            (this.dataLive = [
              {
                name: "Live",
                data: resp.data.live,
              },
            ]),
            (this.dataProces = [
              {
                name: "Prosess",
                data: resp.data.process,
              },
            ]),
            (this.dataTotal = [
              {
                name: "Total",
                data: resp.data.total,
              },
            ]),
            this.getDatastatus();

          console.log(this.childData, "hjd ");

          // return this.dataLeads
          // resp.data.leads
        })
        .catch((e) => {});
    },
    getDatastatus() {
      ApiService.getWithoutSlug(
        "crmv2/main_school/school/school/monitoring/status/2022/1"
      )
        .then((resp) => {
          this.dataLeads.push({
            name: "Leads Proses",
            data: resp.data.leads,
          }),
            this.dataCancel.push({
              name: "Cancel Proses",
              data: resp.data.cancel,
            }),
            this.dataClosing.push({
              name: "Closing Proses",
              data: resp.data.closing,
            });
          this.dataLive.push({
            name: "Live Proses",
            data: resp.data.live,
          }),
            this.dataProces.push({
              name: "Proses Proses",
              data: resp.data.process,
            }),
            this.dataTotal.push({
              name: "Total Proses",
              data: resp.data.total,
            }),
            (this.childData = true);

          // return this.dataLeads
          // resp.data.leads
        })
        .catch((e) => {});
    },
  },
});
